import { Component } from 'preact';

class CV extends Component {
  render() {
    return (
        <div class='container cv'>
          <h2>CV</h2>
          <h3>Key Strengths:</h3>
          <p>A progressive and highly motivated test architect with over 18 years’ experience in large-scale globally distributed programs, with companies such as News Corp, Camelot, Sony PlayStation and River Island</p>
          <p>As test architect I am responsible identifying what tools and technologies should be implemented, aligning with that already used across the broader development function and in-line with the skill-set of the team, as well as designing and developing the test automation framework.</p>
          <p>I take pride in fostering a collaborative and supportive team environment. I provide test leadership to the QA and development team, as well as coaching, mentoring. I am able to motivate a team, recognise good talent and bring out the best in people. I believe teams that work well together increase workplace productivity and create a more cohesive company culture.</p>
          <p>I am results driven, versed in automation and agile software development methodology. I recognise the importance of being agile and continuously improving rather than following a prescriptive process. </p>
          <h3>Work Experience:</h3>
          <div class="title">January 2022 - Present /
            Software Developer in Test - Devops /
            Equal Experts - Government project
          </div>
          <p>Brought in to the team that provisions AWS infrastructure for various application teams,
            initially my job was to test AWS infrastructure and ensure that provisioned applications and databases were set up correctly. The job evolved in to designing and building CI/CD pipelines in Gitlab CI, 
            implementing automated infrastructure tests, using Terratest and Molecule, and picking up feature tickets to provision AWS resources using Terraform</p>
          <ul>
            <li>Designed and implemented multiple CI/CD pipelines that increased testing and reduced time to production for AWS resources
            </li>
            <li>Implemented a Gitlab 'Radiator' that allows the entire project estate to view the status of their Gitlab CI pipelines in a simple web view
            </li>
            <li>Increased testing from zero by championing automated test frameworks such as Terratest, for Terraform, and Molecule, for Ansible.
              Ensured that devops engineers wrote tests for all features. Peer reviewed the tests
            </li>
            <li>Added Health checks to the pipelines, that checked  that the infrastructure was stood up correctly and functioning correctly after being deployed
            </li>
            <li>Provisioned AWS resources using Terraform and Ansible
            </li>
          </ul>
          <hr class="solid" />
          <div class="title">November 2019 - January 2022 /
            Test Architect - Software Developer in Test /
            News UK
          </div>
          <p>Responsible for establishing and implementing the test strategy for News UK’s Content Platforms programme. Content Platforms provides content planning, management, authoring, curation and distribution capabilities that underpins some of the biggest publishing brands in the UK, including The Times and The Sunday Times, and the UK’s most widely read publication, The Sun.</p>
          <ul>
            <li>Architected, designed and built the Content Platforms test
              framework using Cypress, enabling transformation of the QA
              discipline to stop 100% reliance on manual testing
            </li>
            <li>Worked in collaboration with the Head of Engineering to
              develop a plan to shift testing left, changing the mind-set
              within the team to think about quality before development has
              even started
            </li>
            <li>Identified pain points in the QA and release process, then
              coached and mentored the team on how to holistically improve
              quality from requirements gathering through to deployment
            </li>
            <li>Introduced test run automation to drive down deployment
              lifecycle times and improve speed of delivery
            </li>
            <li>Provided consulting to News Corp’s global publishing
              initiative called NewsPress, ensuring best quality practices
              were introduced from the outset of the programme
            </li>
          </ul>
          <hr class="solid" />
          <div class="title">November 2018 – November 2019 /
            Software Developer in Test /
            Clear Channel International
          </div>
          <p>Responsible for implementing the test strategy for the bespoke programmatic advertising platform that transformed Clear Channel from a company selling print-based advertising to a leader in high-tech digital billboards that geographically target out-of-home audiences.</p>
          <ul>
            <li>Architected and built automated test frameworks using Serenity
              BDD across multiple projects, teams and external agencies,
              ensuring consistent implementation for future sustainability. The
              tests are a mixture of front end and REST endpoints, using
              Serenity Rest Assured.
            </li>
            <li>Created Travis CI jobs to ensure that the Automated Test
              framework was part of CD pipeline providing quick feedback during
              every push to the repo
            </li>
            <li>Provided coaching and mentoring to implement QA best practises
            </li>
          </ul>
          <hr class="solid" />
          <div class="title">September 2017 - October 2018 /
            Software Developer in Test /
            PortalTech Reply
          </div>
          <p>PortalTech were building a specialised omni-channel customer engagement and commerce solution for EasyJet. The SAP hybris Airline Accelerator will support EasyJet’s transition from existing e-commerce systems.</p>
          <p>I was a member of the Scrum team building automated java tests for the SAP REST service.</p>
          <ul>
            <li>Architected, designed and built a custom java framework for automated BDD testing of REST services using Cucumber, Maven, Serenity and AssertJ</li>
            <li>Delivered automated test solutions for SOA Web Services</li>
            <li>Collaborated with Development Lead and Architects to build a robust test automation approach</li>
            <li>Implemented the framework and Integrated the framework into the broader application delivery architecture with the development teams using Jenkins and GitLab</li>
            <li>Worked with the technical teams to help resolve issues</li>
            <li>Participated in post-project reviews and feeding back on lessons learned</li>
          </ul>
          <hr class="solid" />
          <div class="title">July 2016 – July 2017 /
            Test Engineer /
            Camelot Global
          </div>
          <p>Test Engineer for the UK lottery platform https://www.national-lottery.co.uk</p>
          <ul>
            <li>Collaboratively created acceptance criteria in BDD cucumber format</li>
            <li>Exploratory tested new features</li>
            <li>Improved process for pushing to master Jenkins pipeline and releases</li>
            <li>Created automated tests in Java and Groovy</li>
            <li>Refactored Geb (webdriver / selenium) web specs and back end Cucumber tests so they were no longer brittle</li>
            <li>Extended the automation framework to include user journeys, that are run on Sauce Labs</li>
            <li>Creating a suite of tests that run on Sauce Labs, using Sauce Labs Connect.</li>
            <li>Tested REST endpoints, using Rest Assured automated tests and Postman</li>
          </ul>
          <hr class="solid" />
          <div class="title">October 2015 – July 2016 /
            Test Engineer /
            Essence Digital
          </div>
          <p>Test engineer for the team that built automated reports for the business and clients of Essence</p>
          <hr class="solid" />
          <div class="title">
            May 2015 – September 2015 /
            Lead Test Engineer /
            River Island
          </div>
          <p>Defined a test strategy for a migration from a managed host to Amazon Web Services (AWS).</p>
          <hr class="solid" />
          <div class="title">
            August 2013 – May 2015 /
            Test Engineer /
            Camelot
          </div>
          <p>Test Engineer for the UK lottery platform https://www.national-lottery.co.uk</p>
          <hr class="solid" />
          <div class="title">
            August 2009 – July 2013 /
            Senior Test Engineer /
            Sony Computer Entertainment
          </div>
          <p>Test engineer for http://playstation.com/</p>
          <hr class="solid" />
          <div class="title">
            February 2009 – April 2009 /
            Test Engineer /
            Key Criteria
          </div>
          <p>Test engineer for Key Criteria</p>
          <hr class="solid" />
          <div class="title">
            October 2008 – December 2008 /
            Test Engineer /
            BSkyB
          </div>
          <p>Test engineer for Sky’s market leading Video on Demand and Streaming Content playe</p>
          <hr class="solid" />
          <div class="title">
            February 2008 – July 2008 /
            Test Engineer /
            Sensis
          </div>
          <p>Test analyst on the Sensis “Morph” program.</p>
          <hr class="solid" />
          <div class="title">
            September 2007 – January 2008 /
            Test Engineer /
            Amsphere
          </div>
          <p>Test engineer on two BizTalk 2006 projects for Amsphere.</p>
          <hr class="solid" />
          <div class="title">
            February 2006 – September 2007 /
            Test Engineer /
            Marks and Spencer
          </div>
          <p>Test engineer for Marks and Spencer’s re-launched E-commerce. </p>
          <hr class="solid" />
          <div class="title">
            April 2005 – February 2006 /
            Test Engineer /
            CoMedica
          </div>
          <p>Test engineer for HL7 applications</p>

        </div>
    );
  }
}

export default CV;

