import { Component } from 'preact';

class Body extends Component {
  render() {
    return (
        <div class='container'>
          <h1>Dan Jones</h1>
          <p>
            Tester.<br />
          </p>
          <ul>
            <li>
              <a href='https://linkedin.com/in/danrhjones' rel='noopener noreferer' target='_blank'>
                LinkedIn
              </a>
            </li>
            <li>
              <a href='https://github.com/danrhjones' rel='noopener noreferer' target='_blank'>
                Github
              </a>
            </li>
          </ul>
        </div>
    );
  }
}

export default Body;

