import './normalize';
import './style';
import { Component } from 'preact';
import { Router } from 'preact-router';
import Body from "./Body";
import CV from "./CV";

export default class App extends Component {

    render() {
      return (
          <div id="app">
            <Body path="/" />
            <Router >
              <CV path="/cv" />
            </Router>
          </div>
      );
    }
}
